import { FILTER_DISPLAY_NAMES } from "@/constants"
import { FilterBox, FilterChip, TextField } from "@/library"
import FilterListIcon from "@mui/icons-material/FilterList"
import GroupIcon from "@mui/icons-material/Group"
import SearchIcon from "@mui/icons-material/Search"
import { Autocomplete, Box, Button, Grid, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"
import PropTypes from "prop-types"
import React, { memo, useCallback, useMemo } from "react"

const FilterInputs = memo(
  ({ filterOptions, filterModel, handleFilterChange, handleRemoveFilter, handleResetFilters }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    const renderTextField = useCallback(
      (label, field, icon) => {
        const filterItem = filterModel.items.find(item => item.field === field)
        return (
          <TextField
            fullWidth
            label={label}
            name={field}
            type="search"
            value={filterItem?.value ?? ""}
            onChange={e => handleFilterChange(field, e.target.value)}
            InputProps={{
              startAdornment: icon,
            }}
            placeholder={label}
            sx={{
              "& .MuiSvgIcon-root.MuiSvgIcon-colorAction": {
                color: theme => (filterItem ? theme.palette.primary.main : theme.palette.text.disabled),
              },
            }}
          />
        )
      },
      [filterModel, handleFilterChange]
    )

    const getAutocompleteValue = useCallback((filterItem, isGroupFilter, options) => {
      if (!filterItem) return isGroupFilter ? [] : null
      return isGroupFilter
        ? options.filter(option => filterItem.value.includes(option.id))
        : options.find(option => option.id === filterItem.value)
    }, [])
    const renderAutocomplete = useCallback(
      (label, field, options, icon) => {
        const filterItem = filterModel.items.find(item => item.field === field)
        const isGroupFilter = field === "groups"

        const value = getAutocompleteValue(filterItem, isGroupFilter, options)

        return (
          <Autocomplete
            multiple={isGroupFilter}
            options={options}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                label={label}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {icon}
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
                placeholder={`Select ${label.toLowerCase()}`}
              />
            )}
            onChange={(event, newValue) => {
              handleFilterChange(field, isGroupFilter ? newValue.map(v => v.id) : newValue?.id ?? null)
            }}
            value={value}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            sx={{
              "& .MuiSvgIcon-root.MuiSvgIcon-colorAction": {
                color: theme =>
                  (isGroupFilter && value.length > 0) || (!isGroupFilter && value)
                    ? theme.palette.primary.main
                    : theme.palette.text.disabled,
              },
            }}
          />
        )
      },
      [filterModel, handleFilterChange, getAutocompleteValue]
    )

    return (
      <FilterBox>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" component="h2">
            <FilterListIcon sx={{ mr: 1, verticalAlign: "middle" }} />
            Student Filters
          </Typography>
          <Tooltip title="Reset all filters">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleResetFilters}
              size={isMobile ? "small" : "medium"}
              startIcon={<FilterListIcon />}
            >
              Reset
            </Button>
          </Tooltip>
        </Box>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12} sm={6} md={4}>
            {renderTextField("Filter by Name", "name", <SearchIcon color="action" />)}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {renderTextField("Filter by Email", "email", <SearchIcon color="action" />)}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {renderAutocomplete("Filter by Group", "groups", filterOptions?.groups ?? [], <GroupIcon color="action" />)}
          </Grid>
        </Grid>
        <FilterChips filterModel={filterModel} filters={filterOptions} handleRemoveFilter={handleRemoveFilter} />
      </FilterBox>
    )
  }
)

FilterInputs.propTypes = {
  filterOptions: PropTypes.object.isRequired,
  filterModel: PropTypes.object.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleRemoveFilter: PropTypes.func.isRequired,
  handleResetFilters: PropTypes.func.isRequired,
}
FilterInputs.displayName = "FilterInputs"

export default FilterInputs

const FilterChips = memo(({ filterModel, filters, handleRemoveFilter }) => {
  const renderFilterValue = useCallback(
    (field, value) => {
      switch (field) {
        case "groups":
        case "grades":
          return Array.isArray(value)
            ? value.map(id => filters[field]?.find(option => option.id === id)?.name ?? "Unknown").join(", ")
            : "Unknown"
        default:
          return filters[field]?.find(option => option.id === value)?.name ?? value
      }
    },
    [filters]
  )

  const memoizedFilterModel = useMemo(() => filterModel, [filterModel])

  if (memoizedFilterModel.items.length === 0) return null
  return (
    <Box mt={2}>
      <Typography variant="subtitle2" gutterBottom>
        Active Filters:
      </Typography>
      {memoizedFilterModel.items.map(
        ({ field, value }) =>
          value && (
            <FilterChip
              key={field}
              label={`${FILTER_DISPLAY_NAMES[field]}: ${renderFilterValue(field, value)}`}
              onDelete={() => handleRemoveFilter(field)}
              color="primary"
              variant="outlined"
            />
          )
      )}
    </Box>
  )
})

FilterChips.propTypes = {
  filterModel: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string,
        value: PropTypes.any,
      })
    ),
  }).isRequired,
  filters: PropTypes.object.isRequired,
  handleRemoveFilter: PropTypes.func.isRequired,
}
FilterChips.displayName = "FilterChips"
