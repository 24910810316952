import { PDF_PRINT_BUTTONS } from "@/constants"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import FilterListIcon from "@mui/icons-material/FilterList"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import React, { memo } from "react"
import { PrintButton } from "./PrintButtons"

const ButtonGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  flexWrap: "wrap",
  margin: theme.spacing(1),
  [theme.breakpoints.up("sm")]: {
    marginLeft: "auto",
  },
}))

const AccordionStyled = styled(Accordion)(({ theme }) => ({
  border: "none",
  borderRadius: 0,
  boxShadow: "none",
  fontFamily: theme.typography.fontFamily,
  borderBottom: "1px solid",
  borderColor: theme.palette.ink[20],
  backgroundColor: theme.palette.ink[5],
  "&:before": {
    display: "none",
  },
}))

const AccordionSummaryStyled = styled(AccordionSummary)(() => ({
  height: 48,
  minHeight: 48,
}))

const StudentsToolbar = ({
  rowSelectionModel,
  handlePrintSelected,
  handlePrintAll,
  FilterInputsComponent,
  showActiveFilters = false,
  filterModel,
  isPrintAllDisabled,
  ...props
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const activeFilters = filterModel?.items.filter(item => item.value).length

  return (
    <>
      <ButtonGroup>
        <PrintButton
          variant={PDF_PRINT_BUTTONS.SELECTED.variant}
          title={PDF_PRINT_BUTTONS.SELECTED.title}
          onClick={handlePrintSelected}
          disabled={!rowSelectionModel.length}
          isMobile={isMobile}
        >
          {PDF_PRINT_BUTTONS.SELECTED.label}
        </PrintButton>
        <PrintButton
          variant={PDF_PRINT_BUTTONS.ALL_ACTIVE.variant}
          title={PDF_PRINT_BUTTONS.ALL_ACTIVE.title}
          onClick={handlePrintAll}
          disabled={isPrintAllDisabled}
          isMobile={isMobile}
          color="primary"
        >
          {PDF_PRINT_BUTTONS.ALL_ACTIVE.label}
        </PrintButton>
      </ButtonGroup>

      <AccordionStyled>
        <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />} aria-controls="filter-content" id="filter-header">
          <Box display="flex" alignItems="center">
            {showActiveFilters && <FilterListIcon sx={{ mr: 1 }} />}
            <Typography variant="subtitle1" fontWeight="700">
              Filters
            </Typography>
            {showActiveFilters && activeFilters > 0 && (
              <Chip
                label={activeFilters}
                size="small"
                sx={{ ml: 1, backgroundColor: theme.palette.neutral[60], color: theme.palette.neutral[120] }}
              />
            )}
          </Box>
        </AccordionSummaryStyled>
        <AccordionDetails>
          <FilterInputsComponent filterModel={filterModel} {...props} />
        </AccordionDetails>
      </AccordionStyled>
    </>
  )
}

export default memo(StudentsToolbar)
