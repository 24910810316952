import { useCallback, useState } from "react"

/**
 * Custom hook to manage the state of a preview dialog.
 *
 * @returns {Object} An object containing the state of the preview dialog and functions to open and close the dialog.
 * @property {Object} previewDialogState - The current state of the preview dialog.
 * @property {boolean} previewDialogState.open - Indicates whether the dialog is open or closed.
 * @property {string|null} previewDialogState.pdfUrl - The URL of the PDF to be previewed, or null if no PDF is set.
 * @property {Function} openPreviewDialog - Function to open the preview dialog with a specified PDF URL.
 * @property {Function} closePreviewDialog - Function to close the preview dialog.
 */
const useDialogState = () => {
  const [previewDialogState, setPreviewDialogState] = useState({
    open: false,
    pdfUrl: null,
    isLoading: false,
  })

  /**
   * Opens the preview dialog with the specified PDF URL.
   *
   * @param {string} pdfUrl - The URL of the PDF to be previewed.
   */
  const openPreviewDialog = useCallback(pdfUrl => {
    setPreviewDialogState(prev => ({
      ...prev,
      open: true,
      pdfUrl,
      isLoading: !pdfUrl, // If no URL is provided, show loading state
    }))
  }, [])

  /**
   * Closes the preview dialog.
   */
  const closePreviewDialog = useCallback(() => {
    setPreviewDialogState({ open: false, pdfUrl: null, isLoading: false })
  }, [])

  /**
   * Sets the loading state of the preview dialog.
   *
   * @param {boolean} isLoading - Indicates whether the dialog is in loading state.
   */
  const setLoading = useCallback(isLoading => {
    setPreviewDialogState(prev => ({ ...prev, isLoading }))
  }, [])

  /**
   * Sets the PDF URL of the preview dialog.
   *
   * @param {string} pdfUrl - The URL of the PDF to be previewed.
   */
  const setPdfUrl = useCallback(pdfUrl => {
    setPreviewDialogState(prev => ({ ...prev, pdfUrl, isLoading: false }))
  }, [])

  return {
    previewDialogState,
    openPreviewDialog,
    closePreviewDialog,
    setLoading,
    setPdfUrl,
  }
}

export default useDialogState
