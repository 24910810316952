import TabsNav from "@/components/blocks/UserTabs"
import BRTThemeProvider from "@/providers/theme"
import { Box, Button, Card, Stack, Typography } from "@mui/material"
import { useGridApiRef } from "@mui/x-data-grid"
import React, { useCallback, useRef, useState } from "react"
import CSVUpload from "./CSVUpload"
import StudentsTable from "./StudentsTable"

const StudentsPage = ({ currentPath, currentUser }) => {
  const apiRef = useGridApiRef()
  const printRef = useRef()
  const [rowSelectionModel, setRowSelectionModel] = useState([])

  const handleRowSelectionModelChange = useCallback(newSelectionModel => {
    setRowSelectionModel(newSelectionModel)
  }, [])

  return (
    <BRTThemeProvider>
      <Box className="container panel-wrapper" sx={{ pb: 4 }}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Typography variant="h2" component="h1" letterSpacing={-0.8} sx={{ color: "#1f007a" }}>
            Students
          </Typography>
          <Stack direction="row" spacing={1} flexWrap="wrap" justifyContent="flex-end">
            <CSVUpload />
            <Button href="/admin/sessions/new" variant="outlined" color="primary">
              Schedule Family Session
            </Button>
            <Button href="/admin/students/new" variant="outlined" color="primary">
              New Student
            </Button>
          </Stack>
        </Stack>
        <Card sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <TabsNav currentUser={currentUser} currentPath={currentPath} />
          <StudentsTable
            apiRef={apiRef}
            rowSelectionModel={rowSelectionModel}
            handleRowSelectionModelChange={handleRowSelectionModelChange}
          />
        </Card>
      </Box>
      <Box ref={printRef} />
    </BRTThemeProvider>
  )
}

export default StudentsPage
