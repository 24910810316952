import Alerter from "@/common/alerter"
import PDFPreviewDialog from "@/common/PDFPreviewDialog"
import { STUDENTS_TABLE_INITIAL_PAGINATION } from "@/constants"
import BRTThemeProvider from "@/providers/theme"
import { Box, Container, Paper, Typography } from "@mui/material"
import { styled } from "@mui/system"
import React, { useCallback, useEffect, useState } from "react"
import AuthenticationModal from "./AuthenticationModal"
import useDialogState from "./hooks/useDialogState"
import useFetchStudents, { fetchStudents } from "./hooks/useFetchStudents"
import useFilters from "./hooks/useFilters"
import StudentsTable from "./studentsTable"

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}))

const StyledHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontWeight: 600,
  color: theme.palette.text.primary,
}))

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: theme.spacing(2),
}))

export const SchoolAdminStudentsPage = ({
  schoolId,
  studentsAPIEndpoint,
  authenticationEndpoint,
  canViewStudentList,
  currentUser,
}) => {
  const [open, setOpen] = useState(!canViewStudentList)
  const [pagination, setPagination] = useState(STUDENTS_TABLE_INITIAL_PAGINATION)
  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const [allStudents, setAllStudents] = useState([])

  const { previewDialogState, openPreviewDialog, closePreviewDialog } = useDialogState()

  const {
    filterOptions,
    filterModel,
    handleFilterModelChange,
    handleFilterChange,
    handleRemoveFilter,
    handleResetFilters,
  } = useFilters(schoolId)

  const {
    data: { students, googleSSOEnabled, totalCount } = {},
    status: { isLoading, error },
    fetchData,
  } = useFetchStudents(studentsAPIEndpoint)

  const handlePageChange = useCallback(({ pageSize, page }) => {
    setPagination(prev => ({ ...prev, page, pageSize }))
  }, [])

  useEffect(() => {
    fetchData(pagination, filterModel)
  }, [pagination, fetchData, filterModel])

  useEffect(() => {
    if (allRowsSelected) {
      const fetchAllStudents = async () => {
        try {
          const result = await fetchStudents(studentsAPIEndpoint, null, null, false, filterModel)
          setAllStudents(result?.students || [])
        } catch (error) {
          Alerter.error("Error fetching all students:", error)
        }
      }

      fetchAllStudents()
    }
  }, [allRowsSelected, filterModel, studentsAPIEndpoint])

  return (
    <BRTThemeProvider>
      <StyledContainer maxWidth="xl">
        {!open ? (
          <Box component="main">
            <StyledHeader variant="h4" component="h1">
              Students
            </StyledHeader>
            <StyledPaper>
              <StudentsTable
                loading={isLoading}
                filterOptions={filterOptions}
                filterModel={filterModel}
                handleFilterChange={handleFilterChange}
                handleRemoveFilter={handleRemoveFilter}
                handleResetFilters={handleResetFilters}
                students={students}
                totalCount={totalCount}
                pagination={pagination}
                googleSSOEnabled={googleSSOEnabled}
                handleOpenPreviewDialog={openPreviewDialog}
                handleClosePreviewDialog={closePreviewDialog}
                handlePageChange={handlePageChange}
                handleFilterModelChange={handleFilterModelChange}
                allRowsSelected={allRowsSelected}
                setAllRowsSelected={setAllRowsSelected}
                allStudents={allStudents}
                currentUser={currentUser}
              />
              {error && <ErrorMessage>Error: {error.message}</ErrorMessage>}
            </StyledPaper>
            <PDFPreviewDialog
              open={previewDialogState.open}
              onClose={closePreviewDialog}
              pdfUrl={previewDialogState.pdfUrl}
              fileName={`school_${schoolId}_student_credentials_${new Date().toISOString()}.pdf`}
              title="Student Credentials PDF"
            />
          </Box>
        ) : (
          <AuthenticationModal endpoint={authenticationEndpoint} setOpen={setOpen} />
        )}
      </StyledContainer>
    </BRTThemeProvider>
  )
}
