import apiClient from "@/api"
import { STUDENT_LOGIN_PDF_URL } from "@/constants"
import { transformFiltersForAPI } from "@/helpers/filterUtils"
import logger from "@/helpers/logger"
import { useCallback } from "react"

export const useStudentPDFGeneration = openPreviewDialog => {
  const generatePDFUrl = useCallback(
    async ({ role = "admin", selectedRows = [], filters = {} } = {}) => {
      try {
        const params = {
          ...(selectedRows?.length && {
            ids: selectedRows,
            status: role === "admin" ? "all" : "active",
          }),
          ...transformFiltersForAPI(filters),
        }

        const response = await apiClient.get(STUDENT_LOGIN_PDF_URL, { params })
        openPreviewDialog(response.data)
      } catch (error) {
        logger.error("Error generating PDF URL:", error)
      }
    },
    [openPreviewDialog]
  )

  return { generatePDFUrl }
}
