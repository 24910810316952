import { breakpoints, colors, dimensions, typography } from "@/library/designTokens"
import { createTheme, responsiveFontSizes } from "@mui/material/styles"

const spacingUnit = 8

let theme = createTheme({
  palette: {
    ...colors,
    background: {
      default: colors.white,
      paper: colors.white,
      secondary: colors.neutral[100],
    },
    text: {
      primary: colors.black,
      secondary: colors.neutral[120],
    },
    action: {
      active: colors.primary.main,
      hover: colors.primary.light,
    },
    divider: colors.neutral[120],
    gradient: {
      secondary: `linear-gradient(90deg, ${colors.secondary.main} 0%, ${colors.secondary.light} 100%)`,
    },
    neutral: {
      primary: colors.neutral[100],
      light: colors.neutral[60],
      dark: colors.neutral[140],
    },
  },
  typography: {
    fontFamily: typography.fontFamilyBase,
    h1: {
      fontWeight: typography.fontWeightSemiBold,
      fontSize: "2.5rem",
      lineHeight: 1.2,
      letterSpacing: "-0.01562em",
    },
    h2: {
      fontWeight: typography.fontWeightSemiBold,
      fontSize: "2rem",
      lineHeight: 1.3,
      letterSpacing: "-0.00833em",
    },
    h3: {
      fontWeight: typography.fontWeightSemiBold,
      fontSize: "1.75rem",
      lineHeight: 1.4,
      letterSpacing: "0em",
    },
    h4: {
      fontWeight: typography.fontWeightSemiBold,
      fontSize: "1.5rem",
      lineHeight: 1.4,
      letterSpacing: "0.00735em",
    },
    h5: {
      fontWeight: typography.fontWeightSemiBold,
      fontSize: "1.25rem",
      lineHeight: 1.5,
      letterSpacing: "0em",
    },
    h6: {
      fontWeight: typography.fontWeightSemiBold,
      fontSize: "1rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
    },
    body1: {
      fontWeight: typography.fontWeightRegular,
      fontSize: "1rem",
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    body2: {
      fontWeight: typography.fontWeightRegular,
      fontSize: "0.875rem",
      lineHeight: 1.43,
      letterSpacing: "0.01071em",
    },
    button: {
      fontWeight: typography.fontWeightBold,
      fontSize: "0.875rem",
      lineHeight: 1.75,
      letterSpacing: "0.02857em",
      textTransform: "none",
    },
  },
  shape: {
    borderRadius: dimensions.borderRadius.medium,
  },
  spacing: spacingUnit,
  breakpoints,
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 6px 0px rgba(0,0,0,0.14),0px 1px 12px 0px rgba(0,0,0,0.12)",
    "0px 4px 6px -2px rgba(0,0,0,0.2),0px 6px 7px 0px rgba(0,0,0,0.14),0px 2px 16px 0px rgba(0,0,0,0.12)",
  ],
  transitions: {
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: dimensions.buttonMinWidth,
          borderRadius: dimensions.borderRadius.pill,
          boxShadow: "none",
          padding: `${dimensions.buttonPaddingY} ${dimensions.buttonPaddingX}`,
          fontFamily: typography.fontFamilyBase,
          fontSize: typography.sizes.button,
          fontWeight: typography.fontWeightSemiBold,
          textTransform: "capitalize",
          transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          lineHeight: "1rem",
          height: "48px",
        },
        contained: {
          "&.Mui-disabled": {
            backgroundColor: colors.ink[20],
            color: colors.ink[60],
          },
        },
        outlined: {
          border: "2px solid",
          "&.Mui-disabled": {
            backgroundColor: colors.ink[20],
            color: colors.ink[60],
          },
        },
        text: {
          "&.Mui-disabled": {
            color: colors.ink[50],
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.ink[100],
          color: colors.white,
          padding: spacingUnit.small,
          maxWidth: dimensions.tooltipMaxWidth,
          fontSize: "0.875rem",
          borderRadius: dimensions.borderRadius.small,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: dimensions.borderRadius.small,
          padding: dimensions.alertPadding,
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          borderRadius: dimensions.borderRadius.large,
          borderColor: "transparent",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          borderColor: "transparent",
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: `${colors.gray} ${colors.white}`,
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: "8px",
            backgroundColor: colors.gray,
          },
          "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
            borderRadius: "8px",
            backgroundColor: colors.white,
          },
        },
      },
    },
    MuiFocusVisible: {
      styleOverrides: {
        root: {
          outline: `2px solid ${colors.primary.main}`,
          outlineOffset: "2px",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            minHeight: "48px !important",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            margin: "0",
          },
        },
      },
    },
  },
})

// Apply responsive font sizes
theme = responsiveFontSizes(theme)

export default theme
