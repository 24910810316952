import React from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"
import { styled } from "@mui/system"
import loading from "@/assets/images/loading.gif"

const SpinnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  margin: theme.spacing(-1),
}))

const SpinnerImage = styled("img")({
  display: "block",
  maxWidth: "100%",
  height: "auto",
})

const LoadingSpinner = ({ size = 72 }) => (
  <SpinnerContainer>
    <SpinnerImage src={loading} alt="Loading" width={size} height={size} />
  </SpinnerContainer>
)

LoadingSpinner.propTypes = {
  size: PropTypes.number,
}

export default React.memo(LoadingSpinner)
