import React, { useCallback, useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material"
import { styled, keyframes } from "@mui/system"
import CloseIcon from "@mui/icons-material/Close"
import DownloadIcon from "@mui/icons-material/Download"
import logger from "@/helpers/logger"
import LoadingSpinner from "./LoadingSpinner"

const PDF_PREVIEW_DIALOG_ID = "pdf-preview-dialog"

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "100%",
    maxWidth: "95vw",
    maxHeight: "95vh",
    margin: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
}))

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(2, 3),
  color: theme.palette.text.primary,
}))

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
  height: "calc(100% - 130px)",
  overflow: "hidden",
  backgroundColor: theme.palette.background.default,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const StyledPDFViewer = styled("embed")(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}))

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  justifyContent: "flex-end",
  backgroundColor: theme.palette.background.paper,
}))

const pulse = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`

const AnimatedTypography = styled(Typography)(() => ({
  animation: `${pulse} 1.5s ease-in-out infinite`,
}))

const PDFPreviewDialog = ({
  open,
  onClose,
  pdfUrl,
  fileName,
  title = "PDF Preview",
  downloadButtonText = "Download PDF",
  closeButtonText = "Close",
}) => {
  const [state, setState] = useState({
    isLoading: false,
    error: null,
    pdfContent: null,
  })

  const { isLoading, error, pdfContent } = state
  useEffect(() => {
    if (!open || !pdfUrl) return

    const fetchPDF = async () => {
      setState(prev => ({ ...prev, isLoading: true, error: null }))
      try {
        setState(prev => ({ ...prev, pdfContent: pdfUrl.pdf, isLoading: false }))
      } catch (err) {
        setState(prev => ({
          ...prev,
          error: "Failed to load PDF. Please try again.",
          isLoading: false,
        }))
      }
    }

    fetchPDF()
  }, [open, pdfUrl])

  const handleClose = useCallback(() => {
    setState({ isLoading: false, error: null, pdfContent: null })
    onClose()
  }, [onClose])

  const handleDownloadPDF = useCallback(() => {
    if (!pdfContent) return
    const linkSource = `data:application/pdf;base64,${pdfContent}`
    const link = document.createElement("a")
    link.href = linkSource
    link.download = fileName
    link.click()
    onClose()
  }, [pdfContent, fileName, onClose])

  const handlePDFLoad = useCallback(() => {
    logger.info("PDF finished loading.")
  }, [])

  const dialogContent = useMemo(
    () =>
      !isLoading && pdfContent ? (
        <Fade in={true} timeout={1000}>
          <StyledPDFViewer
            type="application/pdf"
            src={`data:application/pdf;base64,${pdfContent}`}
            id={PDF_PREVIEW_DIALOG_ID}
            onLoad={handlePDFLoad}
          />
        </Fade>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <LoadingSpinner size={72} />
          <Fade in={true} timeout={1000}>
            <AnimatedTypography variant="body1" color="text.secondary">
              {error || "Loading PDF..."}
            </AnimatedTypography>
          </Fade>
        </Box>
      ),
    [isLoading, pdfContent, error, handlePDFLoad]
  )
  return (
    <StyledDialog
      fullScreen={true}
      open={open}
      onClose={handleClose}
      aria-labelledby="pdf-preview-dialog-title"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 500 }}
    >
      <StyledDialogTitle id="pdf-preview-dialog-title">
        <Typography variant="h6" component="span">
          {title}
        </Typography>
        <Tooltip title="Close">
          <IconButton aria-label="Close dialog" onClick={handleClose} size="large" color="inherit">
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </StyledDialogTitle>
      <StyledDialogContent>{dialogContent}</StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={handleClose} startIcon={<CloseIcon />} color="primary">
          {closeButtonText}
        </Button>
        <Button
          variant="contained"
          onClick={handleDownloadPDF}
          disabled={!pdfContent || isLoading}
          startIcon={<DownloadIcon />}
          color="primary"
        >
          {downloadButtonText}
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  )
}

PDFPreviewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  pdfUrl: PropTypes.shape({
    pdf: PropTypes.string,
  }),
  fileName: PropTypes.string.isRequired,
  title: PropTypes.string,
  downloadButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
}

export default React.memo(PDFPreviewDialog)
