import Alerter from "@/common/alerter"
import BraintrustDataGrid from "@/common/DataGrid/index"
import PDFPreviewDialog from "@/common/PDFPreviewDialog"
import StudentsToolbar from "@/components/common/TableToolbar/StudentsToolbar"
import { MAX_SELECTED_STUDENTS_FOR_PDF } from "@/constants"
import { useStudentPDFGeneration } from "@/hooks/useStudentPDFGeneration"
import { useGridApiRef } from "@mui/x-data-grid"
import React, { useCallback, useMemo, useState } from "react"
import FilterInputs from "./Filters"
import useDialogState from "./hooks/useDialogState"

const INITIAL_DENSITY = "standard"

const transformStudentData = students =>
  students.map((student, index) => ({
    id: index,
    identifier: student.id,
    name: `${student.last_name}, ${student.first_name}`,
    email: student.email,
    password: student.password,
    groups: student.groups,
    grade: student.grade,
    status: student.status === "active" ? "Active" : "Inactive",
  }))

const columns = [
  {
    field: "name",
    headerName: "Name",
    headerClassName: "table-header",
    resizable: false,
    sortable: false,
    flex: 1,
    renderCell: params => (
      <a href={`/school_admin/students/${params.row.identifier}`} style={{ color: "#3c00f5", textDecoration: "none" }}>
        {params.row.name}
      </a>
    ),
  },
  {
    field: "email",
    headerName: "Email",
    headerClassName: "table-header",
    resizable: false,
    sortable: false,
    flex: 1,
  },
  {
    field: "grade",
    headerName: "Grade",
    headerClassName: "table-header",
    resizable: false,
    sortable: false,
    flex: 1,
  },
  {
    field: "groups",
    headerName: "Groups",
    headerClassName: "table-header",
    resizable: false,
    sortable: false,
    flex: 1,
  },
]

const StudentsTable = ({
  students = [],
  totalCount = 0,
  loading,
  pagination,
  handlePageChange,
  handleFilterModelChange,
  filterOptions,
  filterModel,
  handleFilterChange,
  handleRemoveFilter,
  handleResetFilters,
  currentUser,
}) => {
  const apiRef = useGridApiRef()
  const [sortModel, setSortModel] = useState([{ field: "name", sort: "asc" }])
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const [density, setDensity] = useState(INITIAL_DENSITY)
  const { previewDialogState, openPreviewDialog, closePreviewDialog } = useDialogState()
  const { generatePDFUrl } = useStudentPDFGeneration(openPreviewDialog)

  const handlePrintSelected = useCallback(async () => {
    openPreviewDialog()
    try {
      await generatePDFUrl({ role: currentUser.role, selectedRows: rowSelectionModel, filters: filterModel })
    } catch (error) {
      closePreviewDialog()
      Alerter.error("Failed to generate PDF")
    }
  }, [filterModel, generatePDFUrl, openPreviewDialog, closePreviewDialog, rowSelectionModel, currentUser.role])

  const handlePrintAll = useCallback(async () => {
    openPreviewDialog()
    try {
      await generatePDFUrl({ role: currentUser.role, filters: filterModel })
    } catch (error) {
      closePreviewDialog()
      Alerter.error("Failed to generate PDF")
    }
  }, [filterModel, generatePDFUrl, openPreviewDialog, closePreviewDialog, currentUser.role])

  const rows = useMemo(() => transformStudentData(students), [students])

  const isPrintAllDisabled = useMemo(() => {
    return totalCount > MAX_SELECTED_STUDENTS_FOR_PDF || loading
  }, [totalCount, loading])

  return (
    <>
      <BraintrustDataGrid
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        paginationModel={{ page: pagination?.page, pageSize: pagination?.pageSize }}
        onPaginationModelChange={handlePageChange}
        onFilterModelChange={handleFilterModelChange}
        pageSizeOptions={[20, 50, 100]}
        pagination
        filterMode="server"
        paginationMode="server"
        rowCount={totalCount}
        checkboxSelection
        autoHeight
        loading={loading}
        density={density}
        onDensityChange={setDensity}
        getRowId={row => row.identifier}
        onRowSelectionModelChange={setRowSelectionModel}
        rowSelectionModel={rowSelectionModel}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        slots={{ toolbar: StudentsToolbar }}
        disableColumnMenu
        slotProps={{
          toolbar: {
            rows,
            rowSelectionModel,
            handlePrintSelected,
            handlePrintAll,
            FilterInputsComponent: FilterInputs,
            filterOptions,
            filterModel,
            handleFilterChange,
            handleRemoveFilter,
            handleResetFilters,
            isPrintAllDisabled,
          },
        }}
      />
      <PDFPreviewDialog
        {...previewDialogState}
        onClose={closePreviewDialog}
        fileName={`student_credentials_${new Date().toISOString()}.pdf`}
        title="Student Credentials PDF"
      />
    </>
  )
}

export default StudentsTable
