import { apiClient } from "@/api"
import Alerter from "@/common/alerter"
import BraintrustDataGrid from "@/common/DataGrid/index"
import PDFPreviewDialog from "@/common/PDFPreviewDialog"
import StudentsToolbar from "@/components/common/TableToolbar/StudentsToolbar"
import { MAX_SELECTED_STUDENTS_FOR_PDF, STUDENTS_TABLE_INITIAL_PAGINATION } from "@/constants"
import { useStudentPDFGeneration } from "@/hooks/useStudentPDFGeneration"
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import PostAddIcon from "@mui/icons-material/PostAdd"
import { Box, IconButton, Tooltip } from "@mui/material"
import { styled } from "@mui/material/styles"

import React, { useCallback, useEffect, useMemo, useState } from "react"
import FilterInputs from "./Filters"
import useDialogState from "./hooks/useDialogState"
import { transformFiltersForAPI, transformStudentData } from "./utils"

const NameLink = styled("a")(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}))

const fetchStudents = async (studentsAPIEndpoint, options = {}) => {
  const { pageSize, pageNumber, paginate, filters = {}, sorting } = options
  const transformedFilters = transformFiltersForAPI(filters)
  const sortBy = sorting[0]?.field || "name"
  const sortOrder = sorting[0]?.sort || "asc"

  const { data } = await apiClient.get(studentsAPIEndpoint, {
    params: {
      per_page: pageSize,
      page: pageNumber + 1,
      paginate,
      ...transformedFilters,
      sort_by: sortBy,
      sort_order: sortOrder,
    },
  })

  const { students, per_page, total_count, page } = data
  const transformedStudents = transformStudentData(students)
  return {
    students: transformedStudents,
    totalCount: Number(total_count),
    perPage: Number(per_page),
    page: Number(page) - 1,
  }
}

const columns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderCell: params => <NameLink href={`/admin/students/${params.row.id}`}>{params.row.name}</NameLink>,
  },
  { field: "grade", headerName: "Grade", flex: 0.5, filterable: true },
  { field: "school", headerName: "School", flex: 1, filterable: true },
  { field: "parent", headerName: "Parent", flex: 1, filterable: true },
  { field: "groups", headerName: "Groups", flex: 1, filterable: true, type: "string" },
  { field: "assignments", headerName: "Assignments", flex: 0.5, filterable: true, type: "number" },
  {
    field: "status",
    headerName: "Status",
    flex: 0.5,
    filterable: true,
    align: "center",
    headerAlign: "center",
    renderCell: params => (
      <Tooltip title={params.value === "active" ? "Active" : "Inactive"}>
        {params.value === "active" ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
      </Tooltip>
    ),
    valueOptions: ["active", "inactive"],
  },
  {
    field: "new_session_link",
    flex: 0.5,
    sortable: false,
    align: "center",
    type: "actions",
    renderCell: params => (
      <Tooltip title="Create a session">
        <IconButton color="primary" size="small" component="a" href={params.value}>
          <PostAddIcon />
        </IconButton>
      </Tooltip>
    ),
  },
]

const StudentsTable = ({ apiRef, rowSelectionModel, handleRowSelectionModelChange }) => {
  const [data, setData] = useState({ students: [], totalCount: 0, perPage: 50, page: 0 })
  const [status, setStatus] = useState({ isLoading: true, error: null, isInitial: true })
  const [paginationModel, setPaginationModel] = useState(STUDENTS_TABLE_INITIAL_PAGINATION)
  const [density, setDensity] = useState("standard")
  const [sortModel, setSortModel] = useState([{ field: "name", sort: "asc" }])
  const [filterModel, setFilterModel] = useState({ items: [], logicOperator: "and" })

  const { previewDialogState, openPreviewDialog, closePreviewDialog } = useDialogState()
  const { generatePDFUrl } = useStudentPDFGeneration(openPreviewDialog)

  const handlePrintSelected = useCallback(async () => {
    openPreviewDialog()
    try {
      await generatePDFUrl({ role: "admin", selectedRows: rowSelectionModel, filters: filterModel })
    } catch (error) {
      closePreviewDialog()
      Alerter.error("Failed to generate PDF")
    }
  }, [filterModel, generatePDFUrl, openPreviewDialog, closePreviewDialog, rowSelectionModel])

  const handlePrintAll = useCallback(async () => {
    openPreviewDialog()
    try {
      await generatePDFUrl({ role: "admin", filters: filterModel })
    } catch (error) {
      closePreviewDialog()
      Alerter.error("Failed to generate PDF")
    }
  }, [filterModel, generatePDFUrl, openPreviewDialog, closePreviewDialog])

  const handlePageModelChange = useCallback(newModel => {
    setPaginationModel(prev => ({ ...prev, ...newModel }))
  }, [])

  const handleSortModelChange = useCallback(newSortModel => {
    setSortModel(newSortModel)
  }, [])

  const handleDensityChange = useCallback(newDensity => {
    setDensity(newDensity)
  }, [])
  const handleFilterModelChange = useCallback(newModel => {
    setFilterModel(newModel)
    setPaginationModel(STUDENTS_TABLE_INITIAL_PAGINATION)
  }, [])

  const handleResetFilters = useCallback(() => {
    setFilterModel({ items: [], logicOperator: "and" })
    setPaginationModel(STUDENTS_TABLE_INITIAL_PAGINATION)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setStatus(prev => ({ ...prev, isLoading: true }))
      try {
        const result = await fetchStudents("/api/v2/admins/students", {
          pageSize: paginationModel.pageSize,
          pageNumber: paginationModel.page,
          paginate: true,
          sorting: sortModel,
          filters: filterModel,
        })
        setData(result)
      } catch (error) {
        setStatus({ isLoading: false, error })
        Alerter.error("Failed to fetch students data")
      } finally {
        setStatus(prev => ({ ...prev, isLoading: false }))
      }
    }
    fetchData()
  }, [paginationModel, sortModel, filterModel])

  const isPrintAllDisabled = useMemo(() => {
    return data.totalCount > MAX_SELECTED_STUDENTS_FOR_PDF || status.isLoading
  }, [data.totalCount, status.isLoading])

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <BraintrustDataGrid
        apiRef={apiRef}
        rows={data.students}
        rowCount={data.totalCount}
        columns={columns}
        loading={status.isLoading}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePageModelChange}
        onFilterModelChange={handleFilterModelChange}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        density={density}
        onDensityChange={handleDensityChange}
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        slots={{ toolbar: StudentsToolbar }}
        slotProps={{
          toolbar: {
            filterModel,
            onFilterModelChange: handleFilterModelChange,
            onResetFilters: handleResetFilters,
            rowSelectionModel,
            status,
            handlePrintSelected,
            handlePrintAll,
            isPrintAllDisabled,
            showActiveFilters: true,
            FilterInputsComponent: FilterInputs,
          },
        }}
        disableColumnMenu
        disableSelectionOnClick
        checkboxSelection
        keepNonExistentRowsSelected
        autoHeight
      />
      <PDFPreviewDialog
        {...previewDialogState}
        onClose={closePreviewDialog}
        fileName={`student_credentials_${new Date().toISOString()}.pdf`}
        title="Student Credentials PDF"
        loadingMessage="Generating PDF..."
      />
    </Box>
  )
}

export default StudentsTable
