import apiClient from "./client"
import { showAllErrorsInAlert, showBaseErrorsInAlert, showErrorMessage } from "./handlers/errorHandlers"
import {
  deleteRequest,
  get,
  getAndRedirect,
  post,
  postAndRedirect,
  put,
  putAndRedirect,
  request,
} from "./handlers/requestHandlers"
import { replaceId } from "./utils/apiUtils"

export {
  apiClient,
  deleteRequest,
  get,
  getAndRedirect,
  post,
  postAndRedirect,
  put,
  putAndRedirect,
  replaceId,
  request,
  showAllErrorsInAlert,
  showBaseErrorsInAlert,
  showErrorMessage,
}

export default apiClient
