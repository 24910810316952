import { FILTER_API_PARAM_MAP } from "@/constants"

/**
 * Transforms filter model to API-compatible format.
 *
 * @param {Object} filterModel - The filter model to transform.
 * @returns {Object} Transformed filters for API use.
 */
export const transformFiltersForAPI = filterModel => {
  if (!filterModel || !filterModel.items) return {}

  return filterModel.items.reduce((acc, filter) => {
    const { field, operator, value } = filter
    const apiParam = FILTER_API_PARAM_MAP[field] || field

    if (!apiParam) return acc

    if (field === "groups" && Array.isArray(value)) {
      acc[apiParam] = value
      return acc
    }

    let transformedValue = value

    switch (operator) {
      case "contains":
        transformedValue = `%${value}%`
        break
      case "startsWith":
        transformedValue = `${value}%`
        break
      case "endsWith":
        transformedValue = `%${value}`
        break
      case "isEmpty":
        transformedValue = ""
        break
      case "isNotEmpty":
        transformedValue = "!empty"
        break
      case "in":
        if (Array.isArray(value)) {
          acc[apiParam] = value
          return acc
        }
        break
      default:
        break
    }

    if (acc[apiParam]) {
      acc[apiParam] = Array.isArray(acc[apiParam])
        ? [...acc[apiParam], transformedValue]
        : [acc[apiParam], transformedValue]
    } else {
      acc[apiParam] = transformedValue
    }

    return acc
  }, {})
}
