import PrintIcon from "@mui/icons-material/Print"
import { Button, Tooltip } from "@mui/material"
import React, { memo } from "react"

export const PrintButton = memo(({ variant, title, onClick, disabled, children, isMobile }) => (
  <Tooltip title={title}>
    <Button
      size="small"
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      startIcon={!isMobile && <PrintIcon />}
      aria-label={title}
    >
      {isMobile ? <PrintIcon /> : children}
    </Button>
  </Tooltip>
))

PrintButton.displayName = "PrintButton"
